import { ref } from 'vue'
const webComponentsManifest = ref(null)
export const useWebComponents = () => {
  const getManifest = async () => {
    const env = getEnvironment()
    try {
      const response = await fetch(
        `https://assets.diabolocom.ai/standalone/${env}/manifest.json`
      )
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`)
      }

      return await response.json()
    } catch (err) {
      console.error('Error fetching manifest:', err)
      return null
    }
  }

  const getEnvironment = () => {
    //@ts-ignore
    return import.meta.env.VITE_ENV || 'production'
  }

  const loadDevWebComponent = (wc) => {
    return 'http://localhost:8080/cdn/' + wc + '.mjs'
  }

  const loadWebComponents = async () => {
    const components = [
      'ai-details-pane',
      'ai-terms-table',
      'ai-left-pane',
      'ai-agents-performance',
      'ai-insights',
    ]
    try {
      if (!webComponentsManifest.value) {
        throw new Error('Manifest could not be loaded')
      }
      //@ts-ignore
      const useLocalCdn = import.meta.env.VITE_LOCAL_CDN === 'true'

      components.forEach((wc) => {
        const wcPath = useLocalCdn
          ? loadDevWebComponent(wc)
          : webComponentsManifest.value.components[wc].url
        if (!wcPath) {
          console.error(`No valid URL found for ${wc}`)
          return
        }

        // Inject the script dynamically
        const component = document.createElement('script')
        component.setAttribute('src', wcPath)
        component.setAttribute('type', 'module')
        document.head.appendChild(component)
      })
    } catch (err) {
      console.error('Error loading Web Components:', err)
    }
  }

  onBeforeMount(async () => {
    if (!webComponentsManifest.value) {
      webComponentsManifest.value = await getManifest()
      if (webComponentsManifest.value) await loadWebComponents()
    }
  })

  return {
    webComponentsManifest,
  }
}
