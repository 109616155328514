export enum LlmModelsEnums {
  Summarization = 'summarization',
  CallReasonExtraction = 'call-reason-extraction',
  ActionsExtraction = 'actions-extraction',
  Sequencing = 'sequencing',
  NpsEstimation = 'nps-estimation',
  KnowledgeBasedQA = 'knowledge-based-question-answering',
  ContactReasonClassification = 'contact-reason-classification',
  EmailMulticlassTagging = 'email-multiclass-tagging',
  RatingEstimation = 'rating-estimation',
  LanguageDetection = 'language-detection',
  EmailAddressesExtraction = 'email-addresses-extraction',
  LocationsExtraction = 'locations-extraction',
  MonetaryValuesExtraction = 'monetary-values-extraction',
  NamesExtraction = 'names-extraction',
  PhoneNumbersExtraction = 'phone-numbers-extraction',
  ProductsExtraction = 'products-extraction',
  TouchpointsExtraction = 'touchpoints-extraction',
  SatisfactionFactors = 'satisfaction-factors',
  AdvancedTranslation = 'advanced-translation',
}
