import { computed, ref } from 'vue'
import { useApiRepositories } from '@/composables/api/useApiRepositories'
import { useApiJsonValidator } from '@/composables/useApiJsonValidator'
import modelsValidator from '@/jsonValidator/modelsValidator.json'
import { Model } from '@/interfaces/ModelInterface'
import { DocumentTextIcon } from '@heroicons/vue/24/outline'
import { useIcons } from '~/composables/icons/useIcons'
import { LlmModelsEnums } from '~/enums/llmModelsEnums'

const models = ref<Array<Model>>(null)
const loadingModels = ref<boolean>(false)
export const useModels = () => {
  const { modelsRepository } = useApiRepositories()
  const { validateData } = useApiJsonValidator()

  const clearModels = () => {
    models.value = null
  }

  const excludedSlugs: LlmModelsEnums[] = [
    LlmModelsEnums.RatingEstimation,
    LlmModelsEnums.LanguageDetection,
    LlmModelsEnums.EmailAddressesExtraction,
    LlmModelsEnums.LocationsExtraction,
    LlmModelsEnums.MonetaryValuesExtraction,
    LlmModelsEnums.NamesExtraction,
    LlmModelsEnums.PhoneNumbersExtraction,
    LlmModelsEnums.ProductsExtraction,
    LlmModelsEnums.TouchpointsExtraction,
    LlmModelsEnums.SatisfactionFactors,
    LlmModelsEnums.AdvancedTranslation,
  ]

  const getModels = async () => {
    try {
      if (models.value) {
        return
      }
      loadingModels.value = true
      const result = (await modelsRepository.index())?.data
      if (result) {
        const filteredResult = result.filter(
          (model) => !excludedSlugs.includes(model.slug)
        )
        const isValidData = validateData(modelsValidator, filteredResult)
        if (isValidData) {
          models.value = filteredResult
          loadingModels.value = false
        }
      }
    } catch (error) {
      console.log('unable to get models', error)
    }
  }

  const sortModels = (models) => {
    const categoryOrder = { voice: 1, 'voice and text': 2, text: 3, null: 3 }

    return models.sort((a, b) => {
      const orderA = categoryOrder[a.category] ?? 4
      const orderB = categoryOrder[b.category] ?? 4

      if (orderA !== orderB) {
        return orderA - orderB
      }

      return a.title.localeCompare(b.title)
    })
  }

  const { iconsMapping } = useIcons()
  const formattedModelsForSearchPalette = computed(() => {
    if (models.value && models.value.length > 0) {
      return sortModels(
        models.value.map((model) => ({
          title: model.title,
          description: model.description,
          category: model.category ?? null,
          slug: model.slug,
          color: model.color ? `#${model.color}` : `#9F70FD`,
          icon: model.icon ? iconsMapping[model.icon] : DocumentTextIcon,
          secondaryIcon:
            model.category === 'voice and text'
              ? iconsMapping[model.secondary_icon]
              : null,
        }))
      )
    }
    return []
  })

  const refreshModels = () => {
    clearModels()
  }

  return {
    getModels,
    models,
    loadingModels,
    refreshModels,
    formattedModelsForSearchPalette,
    sortModels,
  }
}
