const getBaseUrl = (mocked: boolean = false): string => {
  //@ts-ignore
  if (import.meta.env.DEV) {
    return mocked
      ? //@ts-ignore
        import.meta.env.VITE_MOCK_API_BASE_URL
      : //@ts-ignore
        import.meta.env.VITE_LOCAL_API_BASE_URL
  }
  //@ts-ignore
  return import.meta.env.VITE_API_BASE_URL
}

export const useBaseUrl = (mocked: boolean = false) => {
  const baseUrl = getBaseUrl(mocked)

  return { baseUrl }
}
