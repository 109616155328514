import { createApp } from 'vue'
import pinia from './plugins/pinia-persist.js'
import Vue3EasyDataTable from './plugins/vue3-datatable.js'
import i18n from './plugins/i18n-plugin.js'
import * as Sentry from '@sentry/vue'
import './style.css'
import './../src/assets/css/components.css'
import App from './App.vue'
import axios from 'axios'
import { router } from './router'
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'

import { TagsInput } from '@nor1c/vue-tagsinput'
import vue3GoogleLogin from 'vue3-google-login'
import 'highlight.js/lib/common'
// @ts-ignore
import hljsVuePlugin from '@highlightjs/vue-plugin'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
import { createHead } from '@unhead/vue'
const head = createHead()

// @ts-ignore
axios.defaults.withCredentials = !import.meta.env.DEV
axios.defaults.withXSRFToken = true
axios.defaults.xsrfCookieName = 'XSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN'
const app = createApp(App)

app
  .use(router)
  .use(pinia)
  .use(i18n)
  .use(head)
  .use(hljsVuePlugin)
  .use(VueTelInput)
  .use(vue3GoogleLogin, {
    // @ts-ignore
    clientId: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
  })
  .component('EasyDataTable', Vue3EasyDataTable)
  .component('PrismEditor', PrismEditor)
  .component('tags-input', TagsInput)
  .mount('#app')

Sentry.init({
  app,
  dsn: 'https://1a1c773cf0df6e0b24d4e93c27b8e2a0@sentry.corp.dblc.io/90',
  enabled: ['production', 'sandbox'].includes(
    // @ts-ignore
    import.meta.env.SENTRY_ENVIRONMENT
  ),
  integrations: [Sentry.replayIntegration()],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
